/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;

  .hoverbox-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin: 0 !important;
    display: flex;
  }

  .hoverbox-primary-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
  }

  @include hover-focus {
    .hover-box-content-initial {
      opacity: 0;
    }
    .hoverbox-content {
      opacity: 1;
    }
    .as-hoverbox-content {
      z-index: 1;
    }
  }

}

.hoverbox-content-gradient {
  background: linear-gradient(transparent, $black);
}

// .btn-outline-light:hover {
//   color: #1f1f1f;
//   background-color: $white !important;
// }

.navbar {
  .nav-link {
    @include hover-focus {
      color:$gray-100 !important;
    }
  }
}
.dropdown-menu {
  .dropdown-item {
    transition: color 0.5s;
    @include hover-focus {
      background-color:transparent;
      color:$white !important;
    }
  }
}

.social-icon {
  .fa-circle { color: $white}
  @include hover-focus {
    .fa-circle{
      color: $gray-400;
      transition: all .6s ease-in-out;
    }

  }
}
