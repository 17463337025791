@include media-breakpoint-up(md) {
  .navbar-dropdown-col-4 {
    min-width: 35rem;
  }
}

.navbar {

  /*todo refector*/

  padding-top: 9px !important;
  padding-bottom: 9px !important;
  .dropdown-item {
    font-weight: $font-weight-medium;

  }

  .dropdown-menu {
    background-color: $gray-1100;
    border: 0;
    margin-top: 0;
  }
}

.dropdown-toggle::after {
  display: none;
}


@include media-breakpoint-up(xl) {
  .dropdown-on-hover {
    &.dropdown {
      .dropdown-menu{
        display: block; 
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;}
      @include hover-focus {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
