/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

.z-index-1 {
  z-index: 1;
}

/*-----------------------------------------------
|   Zanimation
-----------------------------------------------*/
*[data-zanim-trigger] {
  opacity: 0;
}

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  top: 85px;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/
@include hover-focus {
  .hover-text-decoration-none {
    text-decoration: none;
  }
}

.resize-none {
  resize: none;
}


/*-----------------------------------------------
|   Dropdown Arrow
-----------------------------------------------*/
.down-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  color: white;
  display: flex;
  justify-content: center;
  height: 120px;
  width: 120px;
}

.indicator-home-arrow {
  display: block;
  position: absolute;
  top: 1.6rem;
  height: .625rem;
  width: .625rem;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    border-left: 2px solid rgba(255, 255, 255, 1);
    border-top: 2px solid rgba(255, 255, 255, 1);
    transform: translate3d(-50%, -50%, 0) rotate(225deg);
  }
}


/*-----------------------------------------------
|   Colors
-----------------------------------------------*/
.radius-secondary{
  border-radius: 10px;
}


