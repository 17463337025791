/*-----------------------------------------------
|   Browser Fixing
-----------------------------------------------*/
// Firefox fixed
.firefox{ .dropcap:first-letter{ margin-top: 0.175em; } }


// Edge fixed
.edge{
  .bg-holder{
    .bg-video{
      height: 100%;
      width: auto;
    }
  }
}


// IE fixed
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img{ max-width: 100%; }
  .card-img-top{ height: 100%; overflow: hidden; }
  .hoverbox-content img{ width: 100%; }
  .min-vh-50 {
    height: 50vh;
  }
  .min-vh-75 {
    height: 75vh;
  }
  .min-vh-100 {
    height: 100vh;
  }
}
