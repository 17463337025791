/*-----------------------------------------------
|   Social Outline Buttons
-----------------------------------------------*/
.btn-outline-facebook {
  @include button-outline-variant($facebook)
}

.btn-outline-twitter {
  @include button-outline-variant($twitter)
}

.btn-outline-google-plus {
  @include button-outline-variant($google-plus)
}

/*-----------------------------------------------
|   Banner Outline Buttons
-----------------------------------------------*/

.btn {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  /*font-size: 0.8439625 rem;*/
}

[class*="btn-outline-"] {
  border-width: 2px;
  text-transform: capitalize;
}
