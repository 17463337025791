  @import 'bootstrap/functions';
  @import 'theme/functions';
  @import 'user-variables';
  @import 'theme/variables';
  @import 'bootstrap/variables';
  @import 'bootstrap/bootstrap';

  /*-----------------------------------------------
  |   Theme Styles
  -----------------------------------------------*/
  @import 'theme/theme';

  /*-----------------------------------------------
  |   User Styles
  -----------------------------------------------*/
  @import 'user';

  .banner-text{
    h1{
      font-weight: 600;
      letter-spacing: 2px;
    }
    a{
    background: #36b3a8;
  }
}
